type ParagraphProps = {
  children: React.ReactNode;
};
function ParagraphHeader({ children }: ParagraphProps) {
  return (
    <h1 className="text-center pt-2 font-bold text-xl sm:text-3xl">
      {children}
    </h1>
  );
}
export default ParagraphHeader;

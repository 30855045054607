import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
interface SubscriptionExpiredProps {
  OnSubscribePage: boolean;
}
function SubscriptionExpired({ OnSubscribePage }: SubscriptionExpiredProps) {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Udløbet abonnement</AlertTitle>
      <AlertDescription>
        Trivselsmails bliver ikke sendt.
        {OnSubscribePage ? (
          " Opret en ny betaling "
        ) : (
          <Button asChild variant={"link"} className=" px-1">
            <Link to="/dashboard/account">Opret en ny betaling</Link>
          </Button>
        )}
        for at fortsætte med at bruge efkt.
      </AlertDescription>
    </Alert>
  );
}

export default SubscriptionExpired;

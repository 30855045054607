import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";

interface ManageSubscriptionCardProps {
  id: string;
}

function ManageSubscriptionCard({ id }: ManageSubscriptionCardProps) {
  const baseUrl = "https://efktprodapi.azurewebsites.net/";
  return (
    <form action={baseUrl + "stripe/create-portal-session"} method="POST">
      <input type="hidden" name="id" value={id} />
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle className="text-center">
            Din konto er <span className="text-primary">aktiv</span>
          </CardTitle>
          <CardDescription className="text-center">
            Du får indsigt i dine medarbejders trivlsel.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <h3 className="text-center">
                Klik nendefor for at adminstrere dit abonnement
              </h3>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" type="submit">
            Administrer
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}

export default ManageSubscriptionCard;

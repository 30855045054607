import { z } from "zod";

export const SignupFormSchema = z
  .object({
    email: z
      .string()
      .min(1, { message: "Brugernavn er påkrævet" })
      .email({ message: "Email er ikke korrekt" }),
    code: z
      .string()
      .min(1, { message: "Koden er påkrævet" })
      .length(4, { message: "Koden skal være 4 cifre lang" }),
    password: z
      .string()
      .min(1, { message: "Password er påkrævet" })
      .max(50, { message: "Password er for langt" }),
    confirm: z
      .string()
      .min(1, { message: "Password er påkrævet" })
      .max(50, { message: "Password er for langt" }),
    companyName: z
      .string()
      .min(1, { message: "Virksomhedsnavn er påkrævet" })
      .max(50, { message: "Virksomhedsnavn er for langt" }),
    cvr: z
      .string()
      .min(1, { message: "CVR er påkrævet" })
      .max(50, { message: "CVR er for langt" }),
    country: z.string().min(1, { message: "Vælg dit land" }),
    field: z.string().min(1, { message: "Vælg din branche" }),
  })
  .refine((data) => data.password === data.confirm, {
    message: "Adgangskoder skal være ens",
    path: ["confirm"],
  });

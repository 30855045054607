import { Link } from "react-router-dom";
import Container from "./Container";
import { Button } from "./ui/button";
import { useTheme } from "./contexts/ThemeProvider";
import { Moon, Sun } from "lucide-react";
import { useAuthContext } from "./contexts/AuthContext";

export default function Header() {
  const routesNotAuthenticated = [{ href: "/login", label: "Log ind" }];
  const routesAuthenticated = [
    { href: "/logout", label: "Log ud" },
    { href: "/dashboard/account", label: "Min profil" },
  ];
  const theme = useTheme();
  const auth = useAuthContext();

  return (
    <div className="py-3 px-4 border-b">
      <Container>
        <div className="flex justify-between">
          <Link to="/" className="text-5xl">
          efkt.nu
          </Link>
          <nav className="flex mx-6 items-center space-x-4 lg:space-x-6 align-text-bottom">
            {auth.isAuthenticated
              ? routesAuthenticated.map((route, i) => (
                  <Button key={i} asChild variant={"ghost"} className="text-lg">
                    <Link to={route.href}>{route.label}</Link>
                  </Button>
                ))
              : routesNotAuthenticated.map((route, i) => (
                  <Button key={i} asChild variant={"ghost"} className="text-lg">
                    <Link to={route.href}>{route.label}</Link>
                  </Button>
                ))}
            <Button
              variant="ghost"
              size="icon"
              aria-label="Toggle Theme"
              className="mr-6"
              onClick={() =>
                theme.setTheme(theme.theme === "dark" ? "light" : "dark")
              }
            >
              <Sun className="h-6 w-6 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Moon className="absolute h-6 w-6 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
              <span className="sr-only">Toggle Theme</span>
            </Button>
          </nav>
        </div>
      </Container>
    </div>
  );
}

import Container from "@/components/Container";
import Paragraph from "@/components/Paragraph";
import Layout from "@/components/layout/Layout";
import ParagraphHeader from "@/components/ui/ParagraphHeader";
import { AboutUs } from "@/data/about";
import ScrollToTop from "@/utils/ScrollToTop";

function AboutPage() {
  return (
    <Layout>
      <ScrollToTop />
      <div className="flex pt-28 justify-center min-h-[calc(100vh-73px)]">
        <Container>
          <Paragraph header="Om os" text={AboutUs} />
          <ParagraphHeader>Website ejes of publiceres af</ParagraphHeader>
          <p className="text-center pt-4 text-lg">
            Kromann Hornung Consult ApS. Hindegade 6, 1303 København K Danmark.
            <br />
            CVR: 42611611
          </p>
        </Container>
      </div>
    </Layout>
  );
}

export default AboutPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import AboutPage from "./routes/about";
import TermsPage from "./routes/terms";
import PricePage from "./routes/price";
import ContactPage from "./routes/contact";
import LoginPage from "./routes/login";
import ForgotPasswordPage from "./routes/login/forgotpassword";
import RegistrationsPage from "./routes/dashboard/registrations";
import AccountPage from "./routes/dashboard/account";
import ActivityPage from "./routes/dashboard/activity";
import SignupPage from "./routes/signup";
import { AuthProvider } from "./components/contexts/AuthContext";
import SignoutPage from "./routes/signout";
import EvaluatePage from "./routes/evaluate";
import ProtectedPage from "./utils/ProtectedPage";
import RegisterEmployeePage from "./routes/registeruser";

const router = createBrowserRouter([
  { path: "/", element: <Root />, errorElement: <ErrorPage /> },
  { path: "/about", element: <AboutPage /> },
  { path: "/terms", element: <TermsPage /> },
  { path: "/prices", element: <PricePage /> },
  { path: "/contact", element: <ContactPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/logout", element: <SignoutPage /> },
  { path: "/login/forgotpassword", element: <ForgotPasswordPage /> },
  { path: "/registeremployee/:id", element: <RegisterEmployeePage /> },
  {
    path: "/dashboard/registrations",
    element: <ProtectedPage page={<RegistrationsPage />} />,
  },
  {
    path: "/dashboard/account",
    element: <ProtectedPage page={<AccountPage />} />,
  },
  {
    path: "/dashboard/activity",
    element: <ProtectedPage page={<ActivityPage />} />,
  },
  { path: "/signup", element: <SignupPage /> },
  { path: "/evaluate/:id/:code/:answer", element: <EvaluatePage /> },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

import Container from "@/components/Container";
import EmailCodeForm from "@/components/forms/passwordreset/EmailCodeForm";
import NewPasswordForm from "@/components/forms/passwordreset/NewPasswordForm";
import LargeHeader from "@/components/ui/LargeHeader";
import { SetStateAction, useState } from "react";

type ForgotPasswordFormProps = {
  setComplete: React.Dispatch<SetStateAction<boolean>>;
};

function ForgotPasswordForm({ setComplete }: ForgotPasswordFormProps) {
  const [isSubmitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  return (
    <Container>
      <div>
        <div className="pb-8 pt-28">
          <LargeHeader>Glemt kodeord</LargeHeader>
        </div>
        {!isSubmitted ? (
          <div className="flex flex-col text-center items-center min-h-[calc(100vh-73px)]">
            <p className="text-lg pb-8">
              Indtast den e-mail der er knyttet til din konto, for at ændre din
              adgangskode
            </p>
            <EmailCodeForm setSubmitted={setSubmitted} setEmail={setEmail} />
          </div>
        ) : (
          <div className="flex flex-col text-center items-center min-h-[calc(100vh-73px)]">
            <p className="text-lg pb-8">
              Verificer din identitet ved at indtaste den kode du har modtaget
              over email, samt din ønskede adgangskode.
            </p>
            <NewPasswordForm setSubmitted={setComplete} email={email} />
          </div>
        )}
      </div>
    </Container>
  );
}

export default ForgotPasswordForm;

import { Answers } from "@/types";
import { ColumnDef } from "@tanstack/react-table";

export const columns: ColumnDef<Answers>[] = [
  {
    accessorKey: "Name",
    header: () => <div className="text-center">Tidspunkt</div>,
  },
  {
    accessorKey: "bad",
    header: () => <div className="text-center">Dårligt</div>,
  },
  {
    accessorKey: "notwell",
    header: () => <div className="text-center">Ikke godt</div>,
  },
  {
    accessorKey: "indifferent",
    header: () => <div className="text-center">Hverken Eller</div>,
  },
  {
    accessorKey: "good",
    header: () => <div className="text-center">Godt</div>,
  },
  {
    accessorKey: "excellent",
    header: () => <div className="text-center">Rigtig Godt</div>,
  },
  {
    accessorKey: "total",
    header: () => <div className="text-center">Antal svar</div>,
  },
];

import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import Layout from "@/components/layout/Layout";

function ErrorPage() {
  // you don't need to explicitly set error to `unknown`
  const error: unknown = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.data || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = "Unknown error";
  }

  return (
    <Layout>
      <div
        id="error-page"
        className="flex flex-col gap-8 justify-center items-center h-screen"
      >
        <h1 className="text-4xl font-bold">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p className="text-slate-400">
          <i>{errorMessage}</i>
        </p>
      </div>
    </Layout>
  );
}

export default ErrorPage;

import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { useTheme } from "./contexts/ThemeProvider";

function IntroHero() {
  const themeProvider = useTheme();
  var theme = "";
  if (themeProvider.theme === "system") {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    theme = systemTheme;
  } else {
    theme = themeProvider.theme;
  }

  return (
    <div className="lg:flex lg:flex-row w-full px-12 space-x-12 lg:justify-between pt-28 overflow-hidden">
      <div className="lg:mt-4 space-y-4">
        <h1 className="text-4xl sm:text-5xl lg:text-4xl font-bold">
          Danmarks mest simple løsning til medarbejder trivsel.
        </h1>
        <h1 className="text-md lg:text-xl max-w-xl">
          Få taget en løbende pulsmåling på medarbejdernes trivsel. Igennem en
          ugentlig måling opnår organisationen indsigt i de mange initiativer
          der årligt gennemføres i virksomheden, og kan let modtage
          tilbagemeldinger og styrke trivslen.
        </h1>
        <Button
          asChild
          size={"sm"}
          className="w-4/12 lg:w-full py-6 text-xl accent-primary-foreground"
        >
          <Link to="/signup">Opret Konto</Link>
        </Button>
      </div>
      <div className="mx-auto">
        <img
          src={theme === "dark" ? "graph-dark.png" : "graph-light.png"}
          alt="graph"
          className="invisible lg:visible max-w-sm lg:max-w-xl object-cover"
        />
      </div>
    </div>
  );
}

export default IntroHero;

import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Label } from "./ui/label";

interface SubScribeCardProps {
  id: string;
  isSignup?: boolean;
}

function SubscribeCard({ id, isSignup }: SubScribeCardProps) {
  const baseUrl = "https://efktprodapi.azurewebsites.net/";
  return (
    <form
      action={
        isSignup
          ? baseUrl + "stripe/signup/create-checkout-session"
          : baseUrl + "stripe/create-checkout-session"
      }
      method="POST"
    >
      <input type="hidden" name="lookup_key" value="stdplan" />
      <input type="hidden" name="id" value={id} />
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle>Standard Abonnement</CardTitle>
          <CardDescription>
            Få indsigt i dine medarbejders trivlsel.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="name">Pris</Label>
              <h3 className="text-center">199 kr / måneden</h3>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" type="submit">
            Videre til betaling
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}

export default SubscribeCard;

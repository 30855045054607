import Container from "@/components/Container";
import RegisterEmployeeForm from "@/components/forms/RegisterEmployeeForm";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

function RegisterEmployeePage() {
  const [isComplete, setIsComplete] = useState(false);
  let { id } = useParams();
  if (!id) return <Navigate to={{ pathname: "/" }} />;
  return (
    <Layout>
      <Container>
        <div className="flex flex-col pt-28 items-center min-h-[calc(100vh-73px)]">
          {!isComplete ? (
            <>
              <LargeHeader>Opret Bruger</LargeHeader>
              <div className="mt-14">
                <RegisterEmployeeForm
                  companyId={id}
                  setIsComplete={setIsComplete}
                />
              </div>
            </>
          ) : (
            <>
              <LargeHeader>Bruger oprettet</LargeHeader>
              <h2 className="mt-8 text-xl">Du kan forlade denne side</h2>
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default RegisterEmployeePage;

import { ParagraphWithHeader } from "@/types";

export const PrivacyPolicy: ParagraphWithHeader[] = [
  [
    "Kromann Hornung Consult ApS (firmaet) er underlagt Databeskyttelsesloven og Databeskyttelsesforordningen, hvilket blandt andet betyder, at vi er forpligtet til at oplyse dig om, hvilke persondata firmaet behandler om dig og hvilke rettigheder du har i den forbindelse. Vi indhenter kun persondata i de tilfælde, hvor dette skulle være relevant for os, og vi vil kun indhente persondata, hvis det er relevant for din aktivitet hos efkt.nu. Ved indsamling, behandling og anvendelse af dine persondata overholder vi altid alle relevante lovbestemmelser. Vi vil kun opbevare dine persondata, så længe vi enten er pålagt en juridisk forpligtelse hertil, eller så længe det er relevant for den hensigt, hvormed de blev indsamlet.",
    "Persondatapolitik hos efkt.nu",
  ],
  [
    "Hvis du ønsker at købe en ydelse fra os, har vi brug for at indsamle visse persondata for at kunne gennemføre oprettelse og for at kunne tilbyde dig vores services. Vi kan bruge cookies til at holde styr på indholdet i din indkøbskurv, mens du bruger vores webshop. Vi vil typisk indhente oplysninger som firmanavn, cvr-nummer, adresse, postnummer, by, mailadresse, land og navn på kontaktperson(er), IP-oplysninger samt betalingsoplysninger. Efkt.nu indsamler og behandler dine persondata, når du foretager dig følgende:",
    "Personoplysninger og formål",
  ],
  [
    "Besøger vores hjemmeside. Gennemfører et køb af vores ydelser. Opretter en konto. Tilmelder dig vores nyhedsbrev. Anmelder vores hjemmeside. Sender os spørgsmål, reklamationer eller feedback",
  ],
  [
    "Ved brug af servicen indsamles navn og e-mail og svar på spørgeskemaet samt evt. fritekst på spørgeskemaet.",
  ],
  [
    "Når du betaler for din service indsamler vi dit navn, dine kortdata og IP-adresse. De oplysninger, der indsamles i forbindelse med betalingstransaktionen, anvendes og gemmes kun til betalingsafvikling og opfyldelse af den indgåede aftale. Ved gennemførsel af betalinger, vil nogle af dine data blive videregivet til betalingsløsningsvirksomheden stripe, hvilket er den information, der er nødvendig for at gennemføre eller understøtte betalingen, såsom det totale købsbeløb og faktureringsinformation.",
  ],
  [
    "Hvis din konto sættes som inaktiv vil den blive slettet efter 2 år. Hvis kontoen ønskes slettet, slettes alle data øjeblikkeligt.",
  ],
  ["Ikke anonymiseret data fra spørgeskema opbevares indtil næste svar, eller konto slettes"],
  [
    "Den dataansvarlige for indsamling, behandling og anvendelse af dine personoplysninger på www.efkt.nu er Kromann Hornung Consult ApS, Hindegade 6, 1303 København K Danmark. CVR: 42611611. Henvendelser kan rettes på e-mail til dpo@efkt.nu.",
    "Den dataansvarlige",
  ],
  [
    "Vi hverken sælger eller overfører dine persondata til tredjemand, udover hostingselskaberne. Vi har vores hjemmeside hos Simply.com og Microsoft Azure. Vores betalingssystem er gennem stripe, som fungerer som vores databehandler. Alle persondata som du oplyser på vores hjemmeside vil blive opbevaret i Microsoft Azure's datacentre. Vi har indgået databehandleraftaler med vores databehandler, hvilket er vores garanti for, at de overholder gældende regler om beskyttelse af dine personoplysninger.",
    "Andre modtagere af personoplysninger",
  ],
  [
    "Du har ret til at få udleveret eller overført dine persondata til en anden dataansvarlig. Hvis du ønsker dette, skal du rette henvendelse til dpo@efkt.nu. Som den registrerede bruger har du ret til at anmode os om følgende: At få adgang til og få rettet/ændret dine persondata (på nær data fra spørgeskema). At få slettet persondata.",
    "Dataportabilitet",
  ],
  [
    "Du har derudover ret til at protestere over behandlingen af dine personlige data, og du har ret til at indgive klage til en databeskyttelsesmyndighed. Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen af dine personoplysninger, kan du også sende os en anmodning herom til vores e-mailadresse dpo@efkt.nu.",
  ],
  [
    "Du har mulighed for at klage over firmaets behandling af dine persondata. Klage kan indgives til Datatilsynet, Borgergade 28, 5, 1300 København K, dt@datatilsynet.dk.",
    "Klage",
  ],
];

export const TOS: ParagraphWithHeader[] = [
  [
    "Alle materielle og immaterielle rettigheder vedrørende efkt.nu ejes og drives af: Kromann Hornung Consult ApS. Hindegade 6, 1303 København K Danmark. CVR: 42611611.",
    "Vilkår og betingelser hos efkt.nu",
  ],
  [
    "Alle priser er vejledende og i danske kroner (DKK) samt angivet eksklusive moms og eventuelle afgifter. Vi forbeholder os ret til at ændre priserne med 3 måneders varsel. Priser er baseret på et månedligt abonnement. Abonnementet starter ved aktivering af kontoen og stopper, hvis kontoen sættes som inaktiv eller slettes helt. Aktivering bekræftes på den oplyste e-mail, samt oplyses på kontoens side.",
    "Priser",
  ],
  [
    "Kromann Hornung Consult ApS modtager betaling i henhold til hvad stripe kan tilbyde. Betalingen trækkes i det øjeblik som virksomhedens konto aktiveres og fortsætter som et månedligt eller årligt abonnement. Alle beløb er i danske kroner (DKK), inklusive moms og transaktionsafgifter. Benyttes en kampagnekode som udløser en rabat, vil denne være fratrukket i hele den periode som kampagnekoden dækker.",
    "Betaling",
  ],
  [
    "Godkendt refusion overføres til det kreditkort, der er brugt til betaling.",
    "Refusion",
  ],
  [
    "I følge købeloven er der ikke fortrydelsesret på digitale produkter, der er taget i brug. Når kontoen sættes som aktiv af kunden, er ydelsen leveret. Lever produktet ikke op til din forventning, så kontakt os venligst.",
    "Fortrydelsesret",
  ],
  [
    "Har du en klage over en ydelse kan der sendes en klage til: Konkurrence- og Forbrugerstyrelsens Center for Klageløsning, Carl Jacobsens Vej 35 2500 Valby - www.forbrug.dk. Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens online klageplatform. Platformen findes her: http://ec.europa.eu/consumers/odr/. Angiver du en klage her, skal du oplyse vores E-mail adresse: kontakt@effekt.nu.",
    "Klagemuligheder – oversigt og links",
  ],
];

export const CookiePolicy: ParagraphWithHeader[] = [
  [
    "Vi indsamler ikke cookies på dette site.",
    "",
  ],
  [
    "Alle materielle og immaterielle rettigheder vedrørende efkt.nu ejes og drives af: Kromann Hornung Consult ApS. Hindegade 6, 1303 København K Danmark. CVR: 42611611",
    "Udgiver",
  ],
];

import Container from "@/components/Container";
import Paragraph from "@/components/Paragraph";
import Layout from "@/components/layout/Layout";
import { CookiePolicy, PrivacyPolicy, TOS } from "@/data/terms";
import { useQuery } from "@/lib/utils";
import ScrollToTop from "@/utils/ScrollToTop";
import { useEffect } from "react";

function TermsPage() {
  const query = useQuery();
  useEffect(() => {
    const scrollToElement = (elementId: string) => {
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    };
    const section = query.get("section")?.toLowerCase();
    if (section) {
      scrollToElement(section);
    }
  }, [query]);
  return (
    <Layout>
      <ScrollToTop />
      <div className="flex pt-20 justify-center min-h-[calc(100vh-73px)]">
        <Container>
          <div id="privacypolicy">
            <Paragraph header="Privatlivspolitik" text={PrivacyPolicy} />
          </div>
          <div id="terms">
            <Paragraph header="Brugsvilkår" text={TOS} />
          </div>
          <div id="cookiepolicy">
            <Paragraph header="Cookiepolitik" text={CookiePolicy} />
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export default TermsPage;

import Container from "@/components/Container";
import RegisterStepper from "@/components/RegisterStepper";
import Layout from "@/components/layout/Layout";
import { useQuery } from "@/lib/utils";

const SignupPage = () => {
  const query = useQuery();
  const success = query.get("success")?.toLowerCase() === "true";
  return (
    <Layout>
      <Container>
        <div className="py-20 mx-10">
          <RegisterStepper success={success} />
        </div>
      </Container>
    </Layout>
  );
};

export default SignupPage;

type ParagraphProps = {
  children: React.ReactNode;
};
function LargeHeader({ children }: ParagraphProps) {
  return (
    <h1 className="text-center font-bold text-primary text-4xl lg:text-7xl">
      {children}
    </h1>
  );
}
export default LargeHeader;

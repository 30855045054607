import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { ButtonLoading } from "../ui/loadingbutton";
import { client } from "@/lib/clientprovider";
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { ButtonLoadingFull } from "../ui/loadingbuttonfull";

interface RegisterEmployeeFormProps {
  companyId: string;
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const formSchema = z.object({
  email: z
    .string()
    .email()
    .min(1, { message: "Email er påkrævet" })
    .max(50, { message: "Email er for lang" }),
  code: z
    .string()
    .min(1, { message: "Koden er påkrævet" })
    .length(4, { message: "Koden skal være 4 cifre lang" }),
});

function RegisterEmployeeForm({
  companyId,
  setIsComplete,
}: RegisterEmployeeFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      code: "",
    },
  });
  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      await client.post("employee", {
        username: values.email,
        companyid: companyId,
        activationcode: values.code,
      });
      setIsLoading(false);
      setIsComplete(true);
    } catch (error) {
      setIsLoading(false);
      form.setError("code", {
        type: "custom",
        message: "Kode er forkert",
      });
    }
  }
  return (
    <Card>
      <CardHeader></CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-80 space-y-4"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>indtast email addresse</FormLabel>
                  <FormControl>
                    <div className="flex flex-row  space-x-2 justify-between">
                      <Input placeholder="email" {...field} />
                      {!isLoading ? (
                        <Button
                          type="button"
                          className="w-36"
                          onClick={async () => {
                            try {
                              setIsLoading(true);
                              await client.put("pending-user", {
                                email: form.getValues().email,
                              });
                              setIsLoading(false);
                              form.clearErrors("email");
                            } catch {
                              setIsLoading(false);
                              form.setError("email", {
                                type: "custom",
                                message: "Email kan ikke bruges",
                              });
                            }
                          }}
                        >
                          Send kode
                        </Button>
                      ) : (
                        <ButtonLoading />
                      )}
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>indtast kode modtaget på mail</FormLabel>
                  <FormControl>
                    <Input placeholder="kode" type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {!isLoading ? (
              <Button type="submit" className="w-full">
                Opret
              </Button>
            ) : (
              <ButtonLoadingFull />
            )}
          </form>
        </Form>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}

export default RegisterEmployeeForm;

import { Loader2 } from "lucide-react";
import { Button } from "./button";

export function ButtonLoadingFull() {
  return (
    <Button disabled className="w-full">
      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      Vent venligst
    </Button>
  );
}

import { Copy } from "lucide-react";
import { DataTable } from "./tables/data-table";
import { columns } from "./tables/employees/columns";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { useAuthContext } from "./contexts/AuthContext";
import { useEffect, useState } from "react";
import { Employee } from "@/types";
import { client } from "@/lib/clientprovider";
import { Skeleton } from "./ui/skeleton";
import { Separator } from "@/components/ui/separator";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";

function RegistrationAndEmployees() {
  const [employees, setEmployees] = useState<Employee[]>();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const auth = useAuthContext();
  const getEmployees = async () => {
    const res = await client.get<Employee[]>("/employees");
    setEmployees((prevEmployees) => {
      // Update state based on previous state
      if (JSON.stringify(prevEmployees) !== JSON.stringify(res.data)) {
        setShowSkeleton(false);
        return res.data;
      }
      return prevEmployees;
    });
  };
  useEffect(() => {
    try {
      // Initial call
      getEmployees();

      // Set up interval to call getEmployees every 1,5 seconds
      const intervalId = setInterval(getEmployees, 1500);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    } catch {
      console.log("error");
    }
  }, [employees]);

  const invitationLink = `efkt.nu/registeremployee/${auth.user?.Company}`;
  return (
    <div className="flex w-full flex-col items-center min-h-[calc(100vh-73px)]">
      <div className="w-9/12 flex flex-col items-center">
        {showSkeleton ? (
          <>
            <div className="items-center flex-col pb-4 pt-8 flex">
              <Skeleton className="h-4 w-[300px] md:w-[500px]" />
              <div className="space-y-10 pt-20">
                <Skeleton className="h-2 w-[300px] md:w-[500px]" />
                <Skeleton className="h-2 w-[300px] md:w-[500px]" />
                <Skeleton className="h-2 w-[300px] md:w-[500px]" />
                <Skeleton className="h-2 w-[300px] md:w-[500px]" />
              </div>
            </div>
          </>
        ) : (
          <>
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Invitationslink</CardTitle>
                <CardDescription>
                  Send dette invitationLink til dine medarbejdere for at
                  invitere dem til din virksomhed hos Efkt.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="justify-center flex">
                  <Input
                    className="md:w-[460px]"
                    disabled
                    type="text"
                    placeholder="Invitationslink"
                    value={invitationLink}
                  />
                  <Button
                    className="ml-2"
                    onClick={() => {
                      navigator.clipboard.writeText(invitationLink);
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
              <CardFooter></CardFooter>
            </Card>
            <Separator className=" my-14" />
            <h2 className="w-full mb-4 px-6 text-xl  font-bold text-start">
              Tilmeldte medarbejdere
            </h2>
            <div className="w-full text-center">
              <DataTable columns={columns} data={employees ?? []} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default RegistrationAndEmployees;

import { client } from "@/lib/clientprovider";
import { Company, User } from "@/types";
import { createContext, useContext, useEffect, useState } from "react";

type AuthProviderProps = { children: React.ReactNode };

interface signInResponse {
  accessToken: string;
  refreshToken: string;
}

type AuthContextState = {
  isAuthenticated: boolean;
  accessToken?: string | null;
  user?: User | undefined;
  company?: Company | undefined;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
};

const AuthProviderContext = createContext<AuthContextState>({
  isAuthenticated: false,
  accessToken: null,
  signIn: (username: string, password: string) => undefined,
  signOut: () => undefined,
});

export function AuthProvider({ children }: AuthProviderProps) {
  const [accessToken, setAccessToken] = useState<string | null>(
    sessionStorage.getItem("token")
  );
  // eslint-disable-next-line
  const [refreshToken, setRefreshToken] = useState<string | null>(
    sessionStorage.getItem("refreshTOken")
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!accessToken
  );
  const [user, setUser] = useState<User | undefined>(undefined);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  const signIn = async (username: string, password: string) => {
    const { data } = await client.post<signInResponse>("auth/login", {
      username,
      password,
    });
    const { accessToken, refreshToken } = data;
    sessionStorage.setItem("token", accessToken);
    setAccessToken(accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
    setRefreshToken(refreshToken);
    setIsAuthenticated(true);
  };

  const signOut = () => {
    sessionStorage.removeItem("token");
    setAccessToken(null);
    sessionStorage.removeItem("refreshToken");
    setRefreshToken(null);
    setIsAuthenticated(false);
  };

  //function getLocalAccessToken() {
  //const accessToken = window.sessionStorage.getItem("token");
  //return accessToken;
  //}

  //function getLocalRefreshToken() {
  //const refreshToken = window.sessionStorage.getItem("refreshToken");
  //return refreshToken;
  //}

  useEffect(() => {
    const getUserData = async () => {
      try {
        var userRes = await client.get<User>("user");
        var companyRes = await client.get<Company>("company");
        setUser(userRes.data);
        setCompany(companyRes.data);
      } catch {}
    };
    setAccessToken(sessionStorage.getItem("token"));
    if (accessToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    if (isAuthenticated) {
      getUserData();
    }
  }, [accessToken, isAuthenticated]);

  const value = {
    isAuthenticated,
    accessToken,
    user,
    company,
    signIn,
    signOut,
  };

  return (
    <AuthProviderContext.Provider value={value}>
      {children}
    </AuthProviderContext.Provider>
  );
}

export const useAuthContext = () => {
  const context = useContext(AuthProviderContext);

  if (context === undefined)
    throw new Error("AuthContext must be used within a AuthProvider");

  return context;
};

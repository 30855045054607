import Container from "@/components/Container";
import Paragraph from "@/components/Paragraph";
import Layout from "@/components/layout/Layout";
import { Prices } from "@/data/prices";
import ScrollToTop from "@/utils/ScrollToTop";

function PricePage() {
  return (
    <Layout>
      <ScrollToTop />
      <div className="flex pt-28 justify-center min-h-[calc(100vh-73px)]">
        <Container>
          <Paragraph header="Priser" text={Prices} />
        </Container>
      </div>
    </Layout>
  );
}

export default PricePage;

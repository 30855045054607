import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  MailSelectorFormSchema,
  days,
  frequencies,
  hours,
} from "@/lib/mailselectorschema";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { client } from "@/lib/clientprovider";
import { useState } from "react";
import { useToast } from "../ui/use-toast";
import { ButtonLoadingFull } from "../ui/loadingbuttonfull";
import { useNavigate } from "react-router-dom";

interface MailSelectorFormProps {
  formDay: string;
  formHour: string;
  formFrequency: string;
  isFirstTime?: boolean;
}

function MailSelectorForm({
  formDay,
  formHour,
  formFrequency,
  isFirstTime,
}: MailSelectorFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof MailSelectorFormSchema>>({
    resolver: zodResolver(MailSelectorFormSchema),
    defaultValues: {
      day: formDay,
      hour: formHour.toString(),
      frequency: formFrequency.toString(),
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof MailSelectorFormSchema>) {
    try {
      setIsLoading(true);
      await client.post(`company/answertime`, values);
      toast({
        description: "Tidspunkt for trivselsmail er indstillet",
      });
      if (isFirstTime) {
        return navigate("/dashboard/registrations");
      }
    } catch {
      toast({
        variant: "destructive",
        title: "Uh oh! Noget gik galt.",
        description: "Der gik noget galt ved opdatering af trivselsmail",
      });
    }
    setIsLoading(false);
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-80 space-y-4">
        <FormField
          control={form.control}
          name="day"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Dag</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={<p className="text-muted-foreground">Dag</p>}
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {days.map((r, i) => (
                    <SelectItem key={i} value={r.day.toString()}>
                      {r.danish}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="hour"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Time</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value.toString()}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        <p className="text-muted-foreground">Branche</p>
                      }
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {hours.map((r, i) => (
                    <SelectItem key={i} value={r.hour.toString()}>
                      {r.value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="frequency"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Hvor ofte?</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value.toString()}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={
                        <p className="text-muted-foreground">Branche</p>
                      }
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {frequencies.map((r, i) => (
                    <SelectItem key={i} value={r.frequency.toString()}>
                      {r.value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isLoading ? (
          <Button className="w-full" type="submit">
            Indstil
          </Button>
        ) : (
          <ButtonLoadingFull />
        )}
      </form>
    </Form>
  );
}

export default MailSelectorForm;

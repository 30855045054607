import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "../ui/textarea";
import { client } from "@/lib/clientprovider";
import { ButtonLoading } from "../ui/loadingbutton";
import React, { useState } from "react";

const formSchema = z.object({
  company: z
    .string()
    .min(1, { message: "Virksomhedsnavn er påkrævet" })
    .max(50, { message: "Virksomhedsnavn er for langt" }),
  name: z
    .string()
    .min(1, { message: "Navn er påkrævet" })
    .max(50, { message: "Navn er for langt" }),
  email: z
    .string()
    .min(1, { message: "Email er påkrævet" })
    .email({ message: "Email er ikke korrekt" }),
  message: z
    .string()
    .min(1, { message: "Besked er påkrævet" })
    .max(500, { message: "Besked er for lang" }),
});

interface ContactFormProps {
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

function ContactForm({ setIsComplete }: ContactFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company: "",
      name: "",
      email: "",
      message: "",
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      await client.post("open/contact", values);
      setIsLoading(false);
      setIsComplete(true);
    } catch {
      setIsLoading(false);
      form.setError("message", {
        type: "custom",
        message: "Noget gik galt",
      });
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-80 space-y-4">
        <FormField
          control={form.control}
          name="company"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Virksomhed" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Navn (fornavn og efternavn)" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea placeholder="Skriv besked" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isLoading ? (
          <Button type="submit" className="w-full">
            Kontakt os
          </Button>
        ) : (
          <ButtonLoading />
        )}
      </form>
    </Form>
  );
}

export default ContactForm;

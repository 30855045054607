import Header from "@/components/Header";
import { ThemeProvider } from "@/components/contexts/ThemeProvider";
import Footer from "../Footer";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider defaultTheme="system">
      <Header />
      {children}
      <Footer />
    </ThemeProvider>
  );
};
export default Layout;

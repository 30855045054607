import { type ClassValue, clsx } from "clsx";
import React from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function convertAnswer(answer: number) {
  switch (answer) {
    case 0:
      return "Dårligt";
    case 1:
      return "Ikke Godt";
    case 2:
      return "Hverken Eller";
    case 3:
      return "Godt";
    case 4:
      return "Rigtig Godt";
    default:
      return "Unknown";
  }
}

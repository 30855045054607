import { z } from "zod";

export const MailSelectorFormSchema = z.object({
  day: z.string().min(1, { message: "Vælg dag" }),
  hour: z.string().min(1, { message: "Vælg tidspunkt" }),
  frequency: z.string().min(0, { message: "Vælg hyppighed" }),
});

export const hours = [
  { hour: 0, value: "00:00" },
  { hour: 1, value: "01:00" },
  { hour: 2, value: "02:00" },
  { hour: 3, value: "03:00" },
  { hour: 4, value: "04:00" },
  { hour: 5, value: "05:00" },
  { hour: 6, value: "06:00" },
  { hour: 7, value: "07:00" },
  { hour: 8, value: "08:00" },
  { hour: 9, value: "09:00" },
  { hour: 10, value: "10:00" },
  { hour: 11, value: "11:00" },
  { hour: 12, value: "12:00" },
  { hour: 13, value: "13:00" },
  { hour: 14, value: "14:00" },
  { hour: 15, value: "15:00" },
  { hour: 16, value: "16:00" },
  { hour: 17, value: "17:00" },
  { hour: 18, value: "18:00" },
  { hour: 19, value: "19:00" },
  { hour: 20, value: "20:00" },
  { hour: 21, value: "21:00" },
  { hour: 22, value: "22:00" },
  { hour: 23, value: "23:00" },
];

export const days = [
  { day: "0", danish: "Mandag" },
  { day: "1", danish: "Tirsdag" },
  { day: "2", danish: "Onsdag" },
  { day: "3", danish: "Torsdag" },
  { day: "4", danish: "Fredag" },
];

export const frequencies = [
  { value: "Hver uge", frequency: 0 },
  { value: "Hver anden uge", frequency: 1 },
  { value: "Hver trejde uge", frequency: 2 },
  { value: "Hver fjerde uge", frequency: 3 },
];

import SubscribeCard from "@/components/SubscribeCard";
import DashboardLayout from "../dashboard-layout";
import ScrollToTop from "@/utils/ScrollToTop";
import MailSelectorForm from "@/components/forms/MailSelectorForm";
import { useAuthContext } from "@/components/contexts/AuthContext";
import ManageSubscriptionCard from "@/components/ManageSubcriptionCard";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";

function AccountPage() {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const auth = useAuthContext();

  useEffect(() => {
    if (auth.company && auth.user) {
      setShowSkeleton(false);
    }
  }, [auth.company, auth.user]);
  return (
    <DashboardLayout title="Konto">
      <ScrollToTop />
      <div className="w-full space-y-8 flex flex-col items-center min-h-[calc(100vh-73px)]">
        <div className="flex flex-col space-y-10 items-center">
          <h2 className="text-2xl font-bold md:text-5xl">
            Administrer Abonnement
          </h2>
          {showSkeleton ? (
            <div className="space-y-6 pt-4">
              <Skeleton className="h-2 w-[300px]" />
              <Skeleton className="h-2 w-[300px]" />
              <Skeleton className="h-2 w-[300px]" />
              <Skeleton className="h-2 w-[300px]" />
            </div>
          ) : auth.company?.SubscriptionActive ? (
            <ManageSubscriptionCard id={auth.user?.Id ?? ""} />
          ) : (
            <SubscribeCard id={auth.user?.Id ?? ""} />
          )}
          <h2 className="text-2xl font-bold md:text-5xl">
            Administrer Trivselsmail
          </h2>
          {showSkeleton ? (
            <div className="space-y-12 pt-4">
              <Skeleton className="h-4 w-[450px]" />
              <Skeleton className="h-4 w-[450px]" />
              <Skeleton className="h-4 w-[450px]" />
              <Skeleton className="h-4 w-[450px]" />
            </div>
          ) : (
            auth.company && (
              <Card>
                <CardHeader>
                  <CardTitle className="text-center">Trivselsmail</CardTitle>
                  <CardDescription className="text-center">
                    Beslut hvornår og hvor ofte dine medarbejdere modtager
                    trivselsmails.
                  </CardDescription>
                </CardHeader>
                <CardContent className=" justify-center flex">
                  <MailSelectorForm
                    formDay={auth.company?.AnswerDay}
                    formFrequency={auth.company?.AnswerFrequency}
                    formHour={auth.company?.AnswerHour}
                  />
                </CardContent>
              </Card>
            )
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AccountPage;

import Container from "@/components/Container";
import ForgotPasswordForm from "@/components/forms/passwordreset/ForgotPasswordForm";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Link } from "react-router-dom";

function ForgotPasswordPage() {
  const [isComplete, setComplete] = useState(false);
  return (
    <Layout>
      {!isComplete ? (
        <ForgotPasswordForm setComplete={setComplete} />
      ) : (
        <Container>
          <div className="flex flex-col items-center text-center mt-12 min-h-[calc(100vh-73px)]">
            <LargeHeader>Ny kode oprettet</LargeHeader>
            <p className="pt-4 text-lg">
              Du har hermed indstillet en ny adgangskode.
              <span>
                <Button asChild variant={"link"} className="text-md">
                  <Link to="/login">Log ind</Link>
                </Button>
              </span>
            </p>
          </div>
        </Container>
      )}
    </Layout>
  );
}

export default ForgotPasswordPage;

import Container from "@/components/Container";
import { SidebarNav } from "@/components/Sidebar-nav";
import SubscriptionExpired from "@/components/SubscriptionExpired";
import { useAuthContext } from "@/components/contexts/AuthContext";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import { Separator } from "@/components/ui/separator";
import { Toaster } from "@/components/ui/toaster";
import { useLocation } from "react-router-dom";

interface DashboardLayoutProps {
  children: React.ReactNode;
  title: string;
}

function DashboardLayout({ children, title }: DashboardLayoutProps) {
  const sidebarNavItems = [
    {
      title: "Aktivitet",
      href: "/dashboard/activity",
    },
    {
      title: "Konto",
      href: "/dashboard/account",
    },
    {
      title: "Tilmeldinger",
      href: "/dashboard/registrations",
    },
  ];
  const auth = useAuthContext();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Layout>
      <Container>
        <div className="py-10">
          <div className="flex flex-col items-center lg:items-start space-y-8 lg:flex-row mx-auto lg:space-y-0">
            <aside className="-mx-4 lg:w-1/5">
              <SidebarNav items={sidebarNavItems} />
            </aside>
            <div className="flex w-full flex-col items-center">
              <div className="w-9/12">
                <LargeHeader>{title}</LargeHeader>
                <Separator className="my-6" />
                {auth.company?.SubscriptionActive !== undefined &&
                  !!auth.company?.SubscriptionActive === false && (
                    <div className="pt-4 pb-10">
                      <SubscriptionExpired
                        OnSubscribePage={pathname === "/dashboard/account"}
                      />
                    </div>
                  )}
              </div>
              {children}
            </div>
          </div>
        </div>
      </Container>
      <Toaster />
    </Layout>
  );
}
export default DashboardLayout;

import { useAuthContext } from "@/components/contexts/AuthContext";
import Layout from "@/components/layout/Layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SignoutPage() {
  const auth = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    auth.signOut();
    navigate("/");
  }, [auth, navigate]);

  return (
    <Layout>
      <div className="flex justify-center items-center min-h-[calc(100vh-73px)]">
        Logger ud...
      </div>
    </Layout>
  );
}

export default SignoutPage;

import ContactForm from "@/components/forms/ContactForm";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import ScrollToTop from "@/utils/ScrollToTop";
import { useState } from "react";

function ContactPage() {
  const [isComplete, setIsComplete] = useState(false);
  return (
    <Layout>
      <ScrollToTop />
      <div className="flex flex-col pt-28 items-center min-h-[calc(100vh-73px)]">
        {!isComplete ? (
          <>
            <div className="pb-8">
              <LargeHeader>Kontakt os</LargeHeader>
            </div>
            <ContactForm setIsComplete={setIsComplete} />
          </>
        ) : (
          <LargeHeader>Din besked er afsendt</LargeHeader>
        )}
      </div>
    </Layout>
  );
}

export default ContactPage;

import RegistrationAndEmployees from "@/components/RegistrationsAndEmployees";
import DashboardLayout from "../dashboard-layout";
import ScrollToTop from "@/utils/ScrollToTop";

function RegistrationsPage() {
  return (
    <DashboardLayout title="Tilmeldinger">
      <ScrollToTop />
      <RegistrationAndEmployees />
    </DashboardLayout>
  );
}

export default RegistrationsPage;

import { ParagraphWithHeader } from "@/types";

export const Paragraph1: ParagraphWithHeader[] = [
  [
    "Det har aldrig være vigtigere, at have en føling med hvordan medarbejderne har det, og dels være i stand til at måle på de personalemæssige tiltag som virksomheden investerer i, og kunne gribe de medarbejdere som måske til tider kan have det svært. En medarbejder som stopper eller sygemelder sig i længere tid, kan være omkostningstungt for virksomheden, men hvis man har mulighed for at kunne gribe ind tidligere, så kunne måske både ”et farvel” og en sygemelding være undgået.",
  ],
  [
    "Ligesom ledelsen gerne vil høres, så vil medarbejderne det også. Efkt.nu er ikke en stor og forkromet medarbejderundersøgelsesløsning, men ment som et løbende supplement til virksomhedens lovpligtige APV og andre store løsninger som gennemføres halv eller helårligt.",
  ],
  [
    "Det handler om indsigt. Indsigt i den generelle trivsel og måling af effekten på de tiltag som virksomheden investerer i, for at højne medarbejder trivslen. De fleste virksomheder ønsker det bedste for deres medarbejdere, men savner værktøjer på hvordan de måler implementerede tiltag, når de rent faktisk implementeres. Samtidig ønsker virksomheder eller organisationer ikke at ”forstyrre” deres medarbejdere ”for meget”, således at de bliver irriteret over for meget administrativt arbejde.",
  ],
  [
    "Efkt.nu er en løsning til private virksomheder såvel som offentlige organisationer, som ønsker at kunne måle trivslen blandt medarbejderne løber. Efkt.nu har skabt en løsning, hvor vi sammen løser følgende:",
  ],
  [
    "En ærlig tilbagemelding fra medarbejderne. Alle besvarelser fra medarbejderne er som udgangspunkt anonyme. Den enkelte medarbejder har mulighed for at ophæve sin anonymitet, hvis denne selv ønsker. Hvis medarbejderen scorer sig selv lavt, og dermed indikerer, at man ikke er glad på sin arbejdsplads, så vil medarbejderen af systemet bliver tilspurgt, om man vil ophæve sin anonymitet. Alle besvarelser samt ophævelser sendes til en valgt HR-medarbejder.",
  ],
  [
    "Indsigt i om et personalemæssigt tiltag har en positiv eller negativ effekt. Det gør vi ved at måle trivslen en gang ugentligt eller hver anden uge.",
  ],
  [
    "Måling af trivslen over tid. Det gør vi ved at stille det samme spørgsmål hver gang, og medarbejderen scorer fra 1 til 5, om hvor ”hvordan man har det på sin arbejdsplads i dag”.",
  ],
];

export const Paragraph2: ParagraphWithHeader[] = [
  [
    'Nu er I i gang! I kan altid justere hyppigheden af målingerne undervejs. Hvis medarbejderne finder det ugentlige spørgsmål for hyppigt, eller omvendt gerne vil måles oftere, kan det nemt tilpasses. Løsningen stiller kun ét enkelt spørgsmål: "Hvordan har du det på din arbejdsplads i dag?". Medarbejderne får derfter 48 timer til at besvare, inden undersøgelsen lukkes.',
  ],
  [
    "Løsningen stiller kun et spørgsmål; ”Hvordan har du det på din arbejdsplads i dag?”. Medarbejderne har herefter 48 timer til at svare på spørgsmålet, herefter lukkes undersøgelsen.",
  ],
];

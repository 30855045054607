import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ButtonLoading } from "./ui/loadingbutton";
import { SignupFormSchema } from "@/lib/signupschema";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import SubscribeCard from "./SubscribeCard";
import {
  MailSelectorFormSchema,
  days,
  frequencies,
  hours,
} from "@/lib/mailselectorschema";
import { client } from "@/lib/clientprovider";
import { signUpResponse } from "@/models/responses/signupresponse";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./contexts/AuthContext";
import RegistrationTermsP from "./forms/RegistrationTermsP";

type Inputs = z.infer<typeof SignupFormSchema>;
type InputsMail = z.infer<typeof MailSelectorFormSchema>;

const steps = [
  {
    id: "Trin 1",
    name: "Opret Bruger",
    fields: ["email", "code", "password", "confirm"],
  },
  {
    id: "Trin 2",
    name: "Opret Virksomhed",
    fields: ["companyName", "cvr", "country", "field"],
  },
  { id: "Trin 3", name: "Indtast betalingsoplysninger" },
  { id: "Trin 4", name: "Indstil trivsel" },
];
const countries = ["Danmark", "Sverige", "Norge"];

const fields = [
  "Bygge og anlæg (anlæg, byggeri, nedrivning)",
  "Butik og engros",
  "Industri og energi",
  "Kontor, it og medier",
  "Landbrug, slagteri og fødevarer",
  "Læger, hospitaler og institutioner",
  "Politi, renovation og anden offentlig service",
  "Offshore",
  "Rengøring, restaurant og anden privat service",
  "Transport af gods og passagerer",
  "Undervisning og forskning",
];

interface RegisterStepperProps {
  success: Boolean | null;
}

function RegisterStepper({ success }: RegisterStepperProps) {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [userId, setUserId] = useState("");
  const form = useForm<Inputs>({
    resolver: zodResolver(SignupFormSchema),
    defaultValues: {
      email: "",
      password: "",
      confirm: "",
      code: "",
      companyName: "",
      cvr: "",
      country: "",
      field: "",
    },
  });

  const mailSelectorForm = useForm<z.infer<typeof MailSelectorFormSchema>>({
    resolver: zodResolver(MailSelectorFormSchema),
    defaultValues: {
      day: "2",
      hour: "12",
      frequency: "0",
    },
  });

  type FieldName = keyof Inputs;
  const next = async () => {
    const fields = steps[currentStep].fields;
    const output = await form.trigger(fields as FieldName[], {
      shouldFocus: true,
    });

    setIsLoading(true);
    if (!output && currentStep !== steps.length - 1) {
      setIsLoading(false);
      return;
    }
    switch (currentStep) {
      case 1:
        await onSubmit(form.getValues());
        break;
      case 3:
        await onSubmitMail(mailSelectorForm.getValues());
        break;
      default:
        nextStep();
        break;
    }
    setIsLoading(false);
  };

  const nextStep = () =>
    setCurrentStep((step) => Math.min(step + 1, steps.length - 1));

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep((step) => step - 1);
    }
  };

  useEffect(() => {
    if (success) setCurrentStep(3);
  }, [success]);

  function handleErrorForm(error: any) {
    switch (error?.response?.data) {
      case "Could not verify user":
        form.setError("code", {
          type: "custom",
          message: "Verifikationskode er ikke korrekt",
        });
        setCurrentStep(0);
        break;
      case "Could not create new user":
        form.setError("email", {
          type: "custom",
          message: "Bruger kan ikke oprettes",
        });
        setCurrentStep(0);
        break;
      case "Company exists":
        form.setError("companyName", {
          type: "custom",
          message: "Virksomheden findes allerede",
        });
        setCurrentStep(1);
        break;
    }
  }

  async function onSubmit(values: Inputs) {
    try {
      const res = await client.post<signUpResponse>("auth/signup", values);
      setUserId(res.data.id);
      await auth.signIn(values.email, values.password);
      nextStep();
    } catch (error) {
      handleErrorForm(error);
    }
  }

  async function onSubmitMail(values: InputsMail) {
    await client.post(`company/answertime`, values);
    navigate("/dashboard/registrations");
  }

  return (
    <>
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step, index) => (
            <li key={step.name} className="md:flex-1">
              {currentStep > index ? (
                <div className="group flex w-full flex-col border-l-4 border-sky-600 py-2 pl-4 transition-colors md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-sm font-medium text-sky-600 transition-colors ">
                    {step.id}
                  </span>
                  <span className="text-sm font-mediu text-muted-foreground">
                    {step.name}
                  </span>
                </div>
              ) : currentStep === index ? (
                <div
                  className="flex w-full flex-col border-l-4 border-sky-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  aria-current="step"
                >
                  <span className="text-sm font-medium text-sky-600">
                    {step.id}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              ) : (
                <div className="group flex w-full flex-col border-l-4 border-gray-200 py-2 pl-4 transition-cors md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-sm font-medium text-gray-500 transition-colors">
                    {step.id}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
      <div className="pt-8 flex flex-col min-h-[calc(100vh-294px)] items-center">
        {currentStep === 0 && (
          <>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="pt-4 space-y-4"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <div className="flex flex-row  justify-between">
                      <FormItem>
                        <FormControl>
                          <Input
                            autoComplete="email"
                            className="w-52"
                            placeholder="Email"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                      {!isLoading ? (
                        <Button
                          type="button"
                          onClick={async () => {
                            try {
                              setIsLoading(true);
                              await client.put("pending-user", {
                                email: form.getValues().email,
                              });
                              setIsLoading(false);
                            } catch {
                              setIsLoading(false);
                              form.setError("email", {
                                type: "custom",
                                message: "Email kan ikke bruges",
                              });
                            }
                          }}
                        >
                          Send kode
                        </Button>
                      ) : (
                        <ButtonLoading />
                      )}
                    </div>
                  )}
                />
                <FormField
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          autoComplete="one-time-code"
                          placeholder="Tilsendt Kode"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Adgangskode"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirm"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Gentag Adgangskode"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <div className="pt-2">
              <RegistrationTermsP />
            </div>
          </>
        )}
        {currentStep === 1 && (
          <>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-80 pt-8 space-y-4"
              >
                <FormField
                  control={form.control}
                  name="companyName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          autoComplete="organization"
                          placeholder="Virksomhedsnavn"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="cvr"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="cvr- / organisations nummer" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="country"
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={
                                <p className="text-muted-foreground">Land</p>
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {countries.map((r, i) => (
                            <SelectItem key={i} value={r.toString()}>
                              {r}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="field"
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={
                                <p className="text-muted-foreground">Branche</p>
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {fields.map((r, i) => (
                            <SelectItem key={i} value={r.toString()}>
                              {r}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <div className="pt-2">
              <RegistrationTermsP />
            </div>
          </>
        )}
        {currentStep === 2 && (
          <>
            <div className="pt-8">
              <SubscribeCard isSignup={true} id={userId} />
            </div>
          </>
        )}
        {currentStep === 3 && (
          <>
          <h1 className="max-w-xl pt-8">Indstil udsendelse af dag, tidspunkt og frekvens for udsendelse af email til medarbejderne. Vinduet for tilbagemelding er åbent i 48 timer. Herefter er det ikke muligt at besvare denne uges pulsmåling.</h1>
            <div className="pt-8">
              <Form {...mailSelectorForm}>
                <form
                  onSubmit={mailSelectorForm.handleSubmit(onSubmitMail)}
                  className="w-80 space-y-4"
                >
                  <FormField
                    control={mailSelectorForm.control}
                    name="day"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  <p className="text-muted-foreground">
                                    Dag
                                  </p>
                                }
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {days.map((r, i) => (
                              <SelectItem key={i} value={r.day.toString()}>
                                {r.danish}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={mailSelectorForm.control}
                    name="hour"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  <p className="text-muted-foreground">
                                    Time
                                  </p>
                                }
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {hours.map((r, i) => (
                              <SelectItem key={i} value={r.hour.toString()}>
                                {r.value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={mailSelectorForm.control}
                    name="frequency"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  <p className="text-muted-foreground">
                                    Frekvens
                                  </p>
                                }
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {frequencies.map((r, i) => (
                              <SelectItem
                                key={i}
                                value={r.frequency.toString()}
                              >
                                {r.value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
            </div>
          </>
        )}
        {currentStep !== 2 && (
          <div className="flex flex-row w-80 justify-between pt-4">
            <Button type="button" disabled={currentStep === 0} onClick={prev}>
              Tilbage
            </Button>
            {isLoading ? (
              <ButtonLoading />
            ) : (
              <Button type="button" onClick={next}>
                Næste
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default RegisterStepper;

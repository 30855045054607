import Container from "@/components/Container";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import { client } from "@/lib/clientprovider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

enum Response {
  "Dårligt",
  "Ikke godt",
  "Hverken eller",
  "Godt",
  "Rigtig godt",
}

function EvaluatePage() {
  let { id, code, answer } = useParams();
  // eslint-disable-next-line
  const [error, setError] = useState("");

  useEffect(() => {
    const postEvaluation = async () => {
      try {
        await client.post("open/answer", { id, code, answer });
      } catch (error: any) {
        switch (
          error?.response?.data
          //case "Pending response error":
          //setError("Dit svar er allerede registreret");
          //break;
          //default:
          //setError("Noget gik galt..");
        ) {
        }
      }
    };
    postEvaluation();
  }, [id, code, answer]);
  return (
    <Layout>
      <Container>
        <div className="flex flex-col items-center space-y-4 pt-20 min-h-[calc(100vh-73px)]">
          {!error ? (
            <>
              <LargeHeader>Tak for dit svar</LargeHeader>
              <p className="max-w-3xl">
                Dit svar er hermed registreret som: "
                {Response[answer as unknown as number]}"
                {/* Dit svar er hermed registreret som: "Godt". Hvis du ønsker at
                knytte en kommentar og/eller vil ophæve din anonymitet kan du
                gøre det nedenfor. Hvis du gør dette vil en HR ansvarlig i din
                virksomhed blive notificeret. */}
              </p>
              {/* <AnswerForm id={id ?? ""} /> */}
            </>
          ) : (
            <LargeHeader>{error}</LargeHeader>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default EvaluatePage;

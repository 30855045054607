import React from "react";
import LargeHeader from "./ui/LargeHeader";
import ParagraphHeader from "./ui/ParagraphHeader";
type ParagraphProps = {
  header?: string;
  text: [string, string?][];
  intro?: React.ReactNode;
};
function Paragraph({ header, text, intro }: ParagraphProps) {
  return (
    <div className="px-4">
      <LargeHeader>{header}</LargeHeader>
      <div className="pt-8 flex flex-col items-center">
        {intro}
        {text.map(([p, t], i) => (
          <div key={i} className="max-w-4xl min-w-full">
            <div className="pb-4">
              <ParagraphHeader>{t}</ParagraphHeader>
            </div>
            <p className="text-lg">{p}</p>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Paragraph;

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonLoadingFull } from "../ui/loadingbuttonfull";

const formSchema = z.object({
  username: z
    .string()
    .min(1, { message: "Brugernavn er påkrævet" })
    .max(50, { message: "Brugernavn er for langt" }),
  password: z
    .string()
    .min(1, { message: "Password er påkrævet" })
    .max(50, { message: "Password er for langt" }),
});

function LoginForm() {
  const [isLoading, setIsloading] = useState(false);
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const auth = useAuthContext();
  const navigate = useNavigate();

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsloading(true);
    try {
      await auth.signIn(values.username, values.password);
      return navigate("/dashboard/activity");
    } catch (error) {
      console.log(error);

      setIsloading(false);
      form.setError("password", {
        type: "custom",
        message: "Brugernavn eller adgangskode er forkert",
      });
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-80 space-y-4">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Brugernavn" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Adgangskode" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isLoading ? (
          <Button type="submit" className="w-full">
            Log ind
          </Button>
        ) : (
          <ButtonLoadingFull />
        )}
      </form>
    </Form>
  );
}

export default LoginForm;

import LoginForm from "@/components/forms/LoginForm";
import Layout from "@/components/layout/Layout";
import LargeHeader from "@/components/ui/LargeHeader";
import { Button } from "@/components/ui/button";
import ScrollToTop from "@/utils/ScrollToTop";
import { Link } from "react-router-dom";

function LoginPage() {
  return (
    <Layout>
      <ScrollToTop />
      <div className="flex flex-col pt-28 items-center min-h-[calc(100vh-73px)]">
        <div className="pb-8">
          <LargeHeader>Log ind</LargeHeader>
        </div>
        <LoginForm />
        <Button asChild variant={"link"} className="text-lg">
          <Link to="/login/forgotpassword">Glemt adgangskode?</Link>
        </Button>
        <h4>
          Har du ingen konto?
          <span>
            <Button asChild variant={"link"} className="text-lg">
              <Link to="/signup">Opret profil her</Link>
            </Button>
          </span>
        </h4>
      </div>
    </Layout>
  );
}

export default LoginPage;

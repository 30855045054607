import { Employee } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { client } from "@/lib/clientprovider";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export const columns: ColumnDef<Employee>[] = [
  {
    accessorKey: "Username",
    header: () => <div className="text-center">Email</div>,
  },
  {
    accessorKey: "Inactive",
    header: () => <div className="text-right">Status</div>,
    cell: ({ row }) => {
      const value = row.getValue("Inactive");
      const formatted = value ? "Inaktiv" : "Aktiv";
      return (
        <div>
          {value ? (
            <div className="text-right font-medium text-muted-foreground">
              {formatted}
            </div>
          ) : (
            <div className="text-right font-medium">{formatted}</div>
          )}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const employee = row.original;
      return (
        <div className="flex justify-center p-0">
          <AlertDialog>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className=" h-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Handlinger</DropdownMenuLabel>
                <DropdownMenuItem>
                  <AlertDialogTrigger asChild>
                    <p>Slet bruger</p>
                  </AlertDialogTrigger>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    client.put("employee/active", {
                      inactive: !employee.Inactive,
                      id: employee.Id,
                    });
                  }}
                >
                  Skift status
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Er du helt sikker?</AlertDialogTitle>
                <AlertDialogDescription>
                  Denne handling kan ikke fortrydes. {employee.Username} vil
                  blive slettet permanent.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Afbryd</AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => {
                    client.delete("employee", {
                      data: { id: employee.Id },
                    });
                  }}
                >
                  Slet
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      );
    },
  },
];

import { Link } from "react-router-dom";
import { Button } from "../ui/button";

function RegistrationTermsP() {
  return (
    <p className="text-xs text-center text-muted-foreground">
      Ved at tilmelde dig accepterer du{" "}
      <Button asChild variant={"link"} className="text-xs h-1 px-0">
        <Link
          to="/terms?section=terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          vilkår
        </Link>
      </Button>{" "}
      for anvendelse <br />
      og{" "}
      <Button asChild variant={"link"} className="text-xs h-1 px-0">
        <Link to="/terms" target="_blank" rel="noopener noreferrer">
          privatlivspolitik
        </Link>
      </Button>{" "}
      , herunder brug af{" "}
      <Button asChild variant={"link"} className="text-xs h-1 px-0">
        <Link
          to="/terms?section=cookiepolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          cookies
        </Link>
      </Button>{" "}
      .
    </p>
  );
}

export default RegistrationTermsP;

import Container from "@/components/Container";
import Paragraph from "@/components/Paragraph";
import Layout from "@/components/layout/Layout";
import { Paragraph1, Paragraph2 } from "@/data/root";
import IntroHero from "@/components/IntroHero";

function Root() {
  const Guide = () => {
    return (
      <div className="pt-5 flex flex-col items-center justify-center">
        <img src="1Circle.png" alt="" loading="lazy" />
        <p className="font-bold max-w-4xl py-4">
          Registrer din virksomhed, ved at klikke på "Opret virksomhed"
        </p>
        <img src="2Circle.png" alt="" />
        <p className="font-bold max-w-4xl py-4">
          Registrer virksomhedens betalingsoplysninger
        </p>
        <img src="3Circle.png" alt="" />
        <p className="font-bold max-w-4xl py-4">Opsæt hvornår der skal måles</p>
        <img src="4Circle.png" alt="" />
        <p className="font-bold max-w-4xl py-4">
          Inviter dine medarbejdere med ét link
        </p>
      </div>
    );
  };
  return (
    <Layout>
      <Container>
        <IntroHero />
        <div className="pt-0 lg:pt-40">
          <Paragraph
            header="Sådan kommer du i gang"
            text={Paragraph2}
            intro={Guide()}
          />
          <Paragraph header="Hvad tilbyder efkt.nu?" text={Paragraph1} />
        </div>
      </Container>
    </Layout>
  );
}

export default Root;

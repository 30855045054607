import { Link } from "react-router-dom";
import Container from "./Container";
import { Button } from "./ui/button";

function Footer() {
  const routes = [
    { href: "/about", title: "Om os" },
    { href: "/contact", title: "Kontakt" },
    { href: "/terms", title: "Vilkår" },
    { href: "/prices", title: "Priser" },
  ];
  return (
    <div className="py-3 px-4 border-t">
      <Container>
        <div className="flex items-center justify-between">
          <h1 className="text-md font-bold">Copyright © 2024 efkt.nu</h1>
          <nav className="flex flex-col mx-6 items-end space-x-4 lg:space-x-6 align-text-bottom">
            {routes.map((r, i) => (
              <div key={i}>
                <Button asChild variant={"ghost"} className="text-lg">
                  <Link to={r.href}>{r.title}</Link>
                </Button>
              </div>
            ))}
          </nav>
        </div>
      </Container>
    </div>
  );
}

export default Footer;

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SetStateAction, useState } from "react";
import { ButtonLoading } from "../../ui/loadingbutton";
import { client } from "@/lib/clientprovider";

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email er påkrævet" })
    .email({ message: "Email er ikke korrekt" }),
});

type EmailCodeFormProps = {
  setSubmitted: React.Dispatch<SetStateAction<boolean>>;
  setEmail: React.Dispatch<SetStateAction<string>>;
};

function EmailCodeForm({ setSubmitted, setEmail }: EmailCodeFormProps) {
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    try {
      await client.post("password-reset", values);
      setLoading(false);
      setEmail(values.email);
      setSubmitted(true);
    } catch {
      setLoading(false);
      form.setError("email", {
        type: "custom",
        message: "Noget gik galt",
      });
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-80 space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex w-full max-w-sm items-center space-x-2">
                  <Input type="email" placeholder="Email" {...field} />
                  {!isLoading ? (
                    <Button type="submit">Næste</Button>
                  ) : (
                    <ButtonLoading />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

export default EmailCodeForm;

import { useAuthContext } from "@/components/contexts/AuthContext";
import { Navigate } from "react-router-dom";

interface ProtectedPageProps {
  page: JSX.Element;
}
function ProtectedPage({ page }: ProtectedPageProps) {
  const auth = useAuthContext();
  if (auth.isAuthenticated) {
    return page;
  } else {
    return <Navigate to={{ pathname: "/login" }} />;
  }
}

export default ProtectedPage;

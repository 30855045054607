import DashboardLayout from "../dashboard-layout";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { convertAnswer } from "@/lib/utils";
import { DataTable } from "@/components/tables/data-table";
import { columns } from "@/components/tables/answers/columns";
import ScrollToTop from "@/utils/ScrollToTop";
import { useEffect, useState } from "react";
import { ActivityResponse, Answers } from "@/types";
import { client } from "@/lib/clientprovider";

function ActivityPage() {
  const [answers, setAnswers] = useState<Answers[]>();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const getAnswers = async () => {
    const res = await client.get<ActivityResponse[]>("/answers");

    const answers = res.data.map((answer) => ({
      Name: new Date(answer.time).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      ...answer,
    }));

    console.log();

    setAnswers((prevAnswers) => {
      // Update state based on previous state
      if (JSON.stringify(prevAnswers) !== JSON.stringify(res.data)) {
        return answers;
      }
      return prevAnswers;
    });
    setShowSkeleton(false);
  };
  useEffect(() => {
    try {
      // Initial call
      getAnswers();
    } catch {
      console.log("error");
    }
  }, []);

  const theme = localStorage.getItem("ui-theme");
  const opacity = theme === "light" ? 1 : 0.1;
  return (
    <DashboardLayout title="Aktivitet">
      <ScrollToTop />
      <div className="w-full flex space-y-10 flex-col items-center min-h-[calc(100vh-73px)]">
        <Card className="w-3/4">
          <CardHeader>
            <CardTitle className="text-center">
              Gennemsnitligt svar pr. måned
            </CardTitle>
          </CardHeader>
          <CardContent className="pl-2">
            <ResponsiveContainer width="100%" height={350}>
              <LineChart data={answers} margin={{ top: 20, right: 20 }}>
                <XAxis
                  dataKey="Name"
                  stroke="#888888"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="#888888"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                  domain={[0, 4]}
                  tickFormatter={(value) => convertAnswer(value)}
                />
                {!showSkeleton && <Line dataKey="average" fill="#adfa1d" />}
                <CartesianGrid strokeDasharray="5 5" opacity={opacity} />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        <div className="w-9/12 text-center">
          <DataTable columns={columns} data={answers ?? []} />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ActivityPage;

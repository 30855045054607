import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SetStateAction, useState } from "react";
import { ButtonLoading } from "../../ui/loadingbutton";
import { client } from "@/lib/clientprovider";

const formSchema = z.object({
  code: z.string().length(7, { message: "Koden skal være 7 karakterer lang" }),
  password: z
    .string()
    .min(1, { message: "Password er påkrævet" })
    .max(50, { message: "Password er for langt" }),
});

type EmailCodeFormProps = {
  setSubmitted: React.Dispatch<SetStateAction<boolean>>;
  email: string;
};

function EmailCodeForm({ setSubmitted, email }: EmailCodeFormProps) {
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    try {
      await client.post("password-reset/verify", { ...values, email: email });
      setLoading(false);
      setSubmitted(true);
    } catch {
      setLoading(false);
      form.setError("password", {
        type: "custom",
        message: "Noget gik galt",
      });
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-80 space-y-4">
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input type="text" placeholder="Kode" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  id="new-password"
                  autoComplete="new-password"
                  type="password"
                  placeholder="Ny adgangskode"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isLoading ? (
          <Button type="submit" className="w-full">
            Næste
          </Button>
        ) : (
          <ButtonLoading />
        )}
      </form>
    </Form>
  );
}

export default EmailCodeForm;
